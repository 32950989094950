<template>
  <div class="result">
    <!--    步骤条区域-->
    <org-apply-steps :activeStep="stepFour" class="apply-steps"></org-apply-steps>
    <div class="content">
<!--      审核通过-->
      <el-result v-if="active" icon="success" subTitle="恭喜，你的服务机构申请已通过">
        <template slot="extra">
          <el-button type="primary" size="medium" class="go-use">去使用</el-button>
        </template>
      </el-result>
<!--      审核不通过-->
      <el-result v-else icon="error" subTitle="抱歉，你的服务机构申请未通过">
        <template slot="extra">
          <div class="btn-box">
            <button class="explain-btn"
                    @click="applyAgain">再次申请</button>
            <el-button type="primary" size="medium">咨询客服</el-button>
          </div>
        </template>
      </el-result>
    </div>
  </div>
</template>

<script>
import orgApplySteps from './orgApplySteps';
export default {
  name: 'orgApplyResult',
  components: {
    orgApplySteps
  },
  data () {
    return {
      active: false,
      stepFour: 3
    };
  },
  methods: {
    applyAgain () {
      this.stepFour = 0;
      this.$router.replace('/orgapply');
    }
  }
};
</script>

<style scoped>
.result {
  position: relative;
  width: 100%;
  height: 374px;
}
.content {
  position: absolute;
  top: 157px;
  left: 50%;
  transform: translateX(-50%);
  width: 224px;
}
.el-result {
  width: 224px;
  padding: 0;
}
/deep/.el-result__subtitle p{
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}
.el-button {
  padding: 0;
  width: 160px;
  height: 46px;
}
.btn-box {
  display: flex;
}
.btn-box .explain-btn {
  width: 160px;
  height: 46px;
  border: 1px solid #156ED0;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  font-weight: bold;
  color: #156ED0;
}
.btn-box .el-button{
  margin-left: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
}
.go-use {
  font-size: 16px;
  font-weight: bold;
  color: #FFFFFF;
}
</style>
